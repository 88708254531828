import React from "react"
import landing from "../img/bg/landing.jpeg"

class PageHeader extends React.Component {
  render() {
    const url = this.props.img ? this.props.img : landing
    return (
      <div className="blue-gradient">
        <div
          style={{
            backgroundImage: "url('" + url + "')",
            backgroundSize: "cover",
          }}
        >
          <div className=" blue-gradient"></div>
          <div className="page-title-area">
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="page-title">
                    <h1>{this.props.title}</h1>
                    <h2>{this.props.subtitle}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PageHeader
