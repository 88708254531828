import React from "react"
import screenshot from "../img/screenshot/stat-screen.png"

class MoreFeatures extends React.Component {
  render() {
    return (
      <div id="features" className="features-area pt-70 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-8 offset-lg-2 offset-xl-3">
              <div className="area-title text-center mb-60">
                <h2 className="mb-15">Features</h2>
                <p></p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-12 col-md-12">
              <div
                className="features-app-thumb mb-60 wow fadeInLeft"
                data-wow-delay="0.2s"
              >
                <img src={screenshot} alt="" />
              </div>
            </div>
            <div className="col-xl-8 col-lg-12 col-md-12">
              <div className="row pt-60">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div
                    className="main-features mb-55 wow fadeIn"
                    data-wow-delay="0.4s"
                  >
                    <div className="features-icon">
                      <span>
                        <i className="ti-clipboard"></i>
                      </span>
                    </div>
                    <div className="features-content">
                      <h4>Programs for every goal</h4>
                      <p>
                        Strength, size, endurance or power. Whatever your goal,
                        we've got you covered.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div
                    className="main-features mb-55 wow fadeIn"
                    data-wow-delay="0.6s"
                  >
                    <div className="features-icon">
                      <span>
                        <i className="ti-dashboard"></i>
                      </span>
                    </div>
                    <div className="features-content">
                      <h4>Difficulty Indicator</h4>
                      <p>
                        We'll tell you how difficult each set will be. Be
                        prepared to challenge in every workout!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div
                    className="main-features mb-55 wow fadeIn"
                    data-wow-delay="0.8s"
                  >
                    <div className="features-icon">
                      <span>
                        <i className="ti-light-bulb"></i>
                      </span>
                    </div>
                    <div className="features-content">
                      <h4>Weight/Rep prediction</h4>
                      <p>
                        Not sure what weight to use or how many reps to peform?
                        We'll tell you both.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div
                    className="main-features mb-55 wow fadeIn"
                    data-wow-delay="1s"
                  >
                    <div className="features-icon">
                      <span>
                        <i className="ti-hummer"></i>
                      </span>
                    </div>
                    <div className="features-content">
                      <h4>Build a workout</h4>
                      <p>Have more freedom by creating your own programs. </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div
                    className="main-features mb-55 wow fadeIn"
                    data-wow-delay="1.2s"
                  >
                    <div className="features-icon">
                      <span>
                        <i className="ti-bar-chart"></i>
                      </span>
                    </div>
                    <div className="features-content">
                      <h4>Progress Statistics</h4>
                      <p>
                        Visualize performance to see improvements with specific
                        exercises.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div
                    className="main-features mb-55 wow fadeIn"
                    data-wow-delay="1.4s"
                  >
                    <div className="features-icon">
                      <span>
                        <i className="ti-bolt"></i>
                      </span>
                    </div>
                    <div className="features-content">
                      <h4>Build a streak</h4>
                      <p>
                        Stay motivated with an in-app streak. Never skip a
                        workout again!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MoreFeatures
